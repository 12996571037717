[data-theme=default] {
  background: #fff;
  color: #000; }
  [data-theme=default] .titles, [data-theme=default] .note {
    border-color: #000; }
  [data-theme=default] button {
    color: #000; }
  [data-theme=default] .loading:after {
    border-bottom-color: #000;
    border-left-color: #000; }
  [data-theme=default] [data-display='large'],
  [data-theme=default] [data-display='small'] {
    fill: #000; }

.cm-s-default.CodeMirror {
  height: 100%;
  background: #fff;
  font-weight: 700;
  font-size: 17px;
  line-height: 19.5px;
  font-family: "Inconsolata", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.cm-s-default .CodeMirror-activeline-background {
  background: #e8f2ff; }

.cm-s-default .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #fff !important; }

.cm-s-default .CodeMirror-linenumber {
  padding: 1px 12px 0 5px;
  color: #999; }

.cm-s-default.CodeMirror-focused .CodeMirror-activeline-gutter .CodeMirror-linenumber {
  color: #999; }

.cm-s-default .CodeMirror-selected {
  background: #d7d4f0; }

.cm-s-default .CodeMirror-cursor {
  border-left: 2px solid #000 !important; }

.cm-s-default .CodeMirror-code {
  color: #000; }

.cm-s-default .CodeMirror-gutters {
  background: rgba(0, 0, 0, 0);
  min-width: 2rem;
  border-right: 0px; }

.cm-s-default .CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto; }

.cm-s-default span.cm-comment {
  font-style: normal;
  color: #236e25; }

.cm-s-default span.cm-atom {
  color: #219; }

.cm-s-default span.cm-number {
  color: #164; }

.cm-s-default span.cm-property {
  color: #000; }

.cm-s-default span.cm-keyword {
  color: #aa0d91; }

.cm-s-default span.cm-string {
  color: #c41a16; }

.cm-s-default span.cm-string-2 {
  color: #c41a16; }

.cm-s-default span.cm-variable {
  color: #000; }

.cm-s-default span.cm-variable-2 {
  color: #05a; }

.cm-s-default span.cm-def {
  color: #00f; }

.cm-s-default span.cm-operator {
  color: #000; }

.cm-s-default span.cm-meta {
  color: #555; }

[data-theme=default] .reflex-container.vertical > .reflex-splitter {
  background: #f1f1f1; }

[data-theme=default] .reflex-container.horizontal > .reflex-splitter {
  background: #f1f1f1; }

[data-theme=default] .reflex-container > .reflex-splitter {
  background: #f1f1f1; }

[data-theme=default] .reflex-container.vertical > .reflex-splitter:hover,
[data-theme=default] .reflex-container.vertical > .reflex-splitter.active,
[data-theme=default] .reflex-container.horizontal > .reflex-splitter:hover,
[data-theme=default] .reflex-container.horizontal > .reflex-splitter.active {
  background: #f1f1f1; }

[data-theme=monochrome] {
  background: #fff;
  color: #495057; }
  [data-theme=monochrome] .titles, [data-theme=monochrome] .note {
    border-color: #495057; }
  [data-theme=monochrome] button {
    color: #495057; }
  [data-theme=monochrome] .loading:after {
    border-bottom-color: #495057;
    border-left-color: #495057; }
  [data-theme=monochrome] [data-display='large'],
  [data-theme=monochrome] [data-display='small'] {
    fill: #495057; }

.cm-s-monochrome.CodeMirror {
  height: 100%;
  background: #fff;
  font-weight: 700;
  font-size: 17px;
  line-height: 19.5px;
  font-family: "Inconsolata", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.cm-s-monochrome .CodeMirror-activeline-background {
  background: #fff; }

.cm-s-monochrome .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #fff !important; }

.cm-s-monochrome .CodeMirror-linenumber {
  padding: 1px 12px 0 5px;
  color: rgba(173, 181, 189, 0.75); }

.cm-s-monochrome.CodeMirror-focused .CodeMirror-activeline-gutter .CodeMirror-linenumber {
  color: rgba(33, 37, 41, 0.75); }

.cm-s-monochrome .CodeMirror-selected {
  background: #f1f3f5; }

.cm-s-monochrome .CodeMirror-cursor {
  border-left: 2px solid #00baff !important; }

.cm-s-monochrome .CodeMirror-code {
  color: #495057; }

.cm-s-monochrome .CodeMirror-gutters {
  background: rgba(0, 0, 0, 0);
  min-width: 2rem;
  border-right: 0px; }

.cm-s-monochrome .CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto; }

.cm-s-monochrome span.cm-comment {
  font-style: normal;
  color: #adb5bd; }

.cm-s-monochrome span.cm-atom {
  color: #495057; }

.cm-s-monochrome span.cm-number {
  color: #495057; }

.cm-s-monochrome span.cm-property {
  color: #495057; }

.cm-s-monochrome span.cm-keyword {
  color: #495057; }

.cm-s-monochrome span.cm-string {
  color: #868e96; }

.cm-s-monochrome span.cm-string-2 {
  color: #868e96; }

.cm-s-monochrome span.cm-variable {
  color: #495057; }

.cm-s-monochrome span.cm-variable-2 {
  color: #495057; }

.cm-s-monochrome span.cm-def {
  color: #495057; }

.cm-s-monochrome span.cm-operator {
  color: #495057; }

.cm-s-monochrome span.cm-meta {
  color: #495057; }

[data-theme=monochrome] .reflex-container.vertical > .reflex-splitter {
  background: #f9f9f9; }

[data-theme=monochrome] .reflex-container.horizontal > .reflex-splitter {
  background: #f9f9f9; }

[data-theme=monochrome] .reflex-container > .reflex-splitter {
  background: #f9f9f9; }

[data-theme=monochrome] .reflex-container.vertical > .reflex-splitter:hover,
[data-theme=monochrome] .reflex-container.vertical > .reflex-splitter.active,
[data-theme=monochrome] .reflex-container.horizontal > .reflex-splitter:hover,
[data-theme=monochrome] .reflex-container.horizontal > .reflex-splitter.active {
  background: #f9f9f9; }

[data-theme=monochrome-dark] {
  background: #1c2023;
  color: #bfbfbf; }
  [data-theme=monochrome-dark] .titles, [data-theme=monochrome-dark] .note {
    border-color: #bfbfbf; }
  [data-theme=monochrome-dark] button {
    color: #bfbfbf; }
  [data-theme=monochrome-dark] .loading:after {
    border-bottom-color: #bfbfbf;
    border-left-color: #bfbfbf; }
  [data-theme=monochrome-dark] [data-display='large'],
  [data-theme=monochrome-dark] [data-display='small'] {
    fill: #bfbfbf; }

.cm-s-monochrome-dark.CodeMirror {
  height: 100%;
  background: #1c2023;
  font-weight: 700;
  font-size: 17px;
  line-height: 19.5px;
  font-family: "Inconsolata", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.cm-s-monochrome-dark .CodeMirror-activeline-background {
  background: #1c2023; }

.cm-s-monochrome-dark .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #1c2023 !important; }

.cm-s-monochrome-dark .CodeMirror-linenumber {
  padding: 1px 12px 0 5px;
  color: #4e5661; }

.cm-s-monochrome-dark.CodeMirror-focused .CodeMirror-activeline-gutter .CodeMirror-linenumber {
  color: #bfbfbf; }

.cm-s-monochrome-dark .CodeMirror-selected {
  background: #2a3135; }

.cm-s-monochrome-dark .CodeMirror-cursor {
  border-left: 2px solid #00baff !important; }

.cm-s-monochrome-dark .CodeMirror-code {
  color: #bfbfbf; }

.cm-s-monochrome-dark .CodeMirror-gutters {
  background: rgba(0, 0, 0, 0);
  min-width: 2rem;
  border-right: 0px; }

.cm-s-monochrome-dark .CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto; }

.cm-s-monochrome-dark span.cm-comment {
  font-style: normal;
  color: #4a4f58; }

.cm-s-monochrome-dark span.cm-atom {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-number {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-property {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-keyword {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-string {
  color: #575f6b; }

.cm-s-monochrome-dark span.cm-string-2 {
  color: #575f6b; }

.cm-s-monochrome-dark span.cm-variable {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-variable-2 {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-def {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-operator {
  color: #bfbfbf; }

.cm-s-monochrome-dark span.cm-meta {
  color: #bfbfbf; }

[data-theme=monochrome-dark] .reflex-container.vertical > .reflex-splitter {
  background: #24282d; }

[data-theme=monochrome-dark] .reflex-container.horizontal > .reflex-splitter {
  background: #24282d; }

[data-theme=monochrome-dark] .reflex-container > .reflex-splitter {
  background: #24282d; }

[data-theme=monochrome-dark] .reflex-container.vertical > .reflex-splitter:hover,
[data-theme=monochrome-dark] .reflex-container.vertical > .reflex-splitter.active,
[data-theme=monochrome-dark] .reflex-container.horizontal > .reflex-splitter:hover,
[data-theme=monochrome-dark] .reflex-container.horizontal > .reflex-splitter.active {
  background: #24282d; }

[data-theme=ocean-dark] {
  background: #2b303b;
  color: #c0c5ce; }
  [data-theme=ocean-dark] .titles, [data-theme=ocean-dark] .note {
    border-color: #c0c5ce; }
  [data-theme=ocean-dark] button {
    color: #c0c5ce; }
  [data-theme=ocean-dark] .loading:after {
    border-bottom-color: #c0c5ce;
    border-left-color: #c0c5ce; }
  [data-theme=ocean-dark] [data-display='large'],
  [data-theme=ocean-dark] [data-display='small'] {
    fill: #c0c5ce; }

.cm-s-ocean-dark.CodeMirror {
  height: 100%;
  background: #2b303b;
  font-weight: 700;
  font-size: 17px;
  line-height: 19.5px;
  font-family: "Inconsolata", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.cm-s-ocean-dark .CodeMirror-activeline-background {
  background: #2b303b; }

.cm-s-ocean-dark .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #fff !important; }

.cm-s-ocean-dark .CodeMirror-linenumber {
  padding: 1px 12px 0 5px;
  color: #c0c5ce; }

.cm-s-ocean-dark.CodeMirror-focused .CodeMirror-activeline-gutter .CodeMirror-linenumber {
  color: #c0c5ce; }

.cm-s-ocean-dark .CodeMirror-selected {
  background: #505b66; }

.cm-s-ocean-dark .CodeMirror-cursor {
  border-left: 2px solid #c0c5ce !important; }

.cm-s-ocean-dark .CodeMirror-code {
  color: #c0c5ce; }

.cm-s-ocean-dark .CodeMirror-gutters {
  background: rgba(0, 0, 0, 0);
  min-width: 2rem;
  border-right: 0px; }

.cm-s-ocean-dark .CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto; }

.cm-s-ocean-dark span.cm-comment {
  font-style: italic;
  color: #65737e; }

.cm-s-ocean-dark span.cm-atom {
  color: #d08770; }

.cm-s-ocean-dark span.cm-number {
  color: #d08770; }

.cm-s-ocean-dark span.cm-property {
  color: #bf616a; }

.cm-s-ocean-dark span.cm-keyword {
  color: #b48ead; }

.cm-s-ocean-dark span.cm-string {
  color: #a3be8c; }

.cm-s-ocean-dark span.cm-string-2 {
  color: #a3be8c; }

.cm-s-ocean-dark span.cm-variable {
  color: #8fa1b3; }

.cm-s-ocean-dark span.cm-variable-2 {
  color: #c0c5ce; }

.cm-s-ocean-dark span.cm-def {
  color: #c0c5ce; }

.cm-s-ocean-dark span.cm-operator {
  color: #c0c5ce; }

.cm-s-ocean-dark span.cm-meta {
  color: #c0c5ce; }

[data-theme=ocean-dark] .reflex-container.vertical > .reflex-splitter {
  background: #373b45; }

[data-theme=ocean-dark] .reflex-container.horizontal > .reflex-splitter {
  background: #373b45; }

[data-theme=ocean-dark] .reflex-container > .reflex-splitter {
  background: #373b45; }

[data-theme=ocean-dark] .reflex-container.vertical > .reflex-splitter:hover,
[data-theme=ocean-dark] .reflex-container.vertical > .reflex-splitter.active,
[data-theme=ocean-dark] .reflex-container.horizontal > .reflex-splitter:hover,
[data-theme=ocean-dark] .reflex-container.horizontal > .reflex-splitter.active {
  background: #373b45; }

* {
  box-sizing: border-box; }

p {
  margin: 0; }

a {
  text-decoration: none;
  color: inherit;
  outline: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

img, i {
  vertical-align: bottom; }

::selection {
  background: #f1f3f5; }

button {
  outline: 0;
  background: none;
  border: none;
  font-weight: 500;
  padding: 0; }
  button:hover {
    cursor: pointer; }

.btn {
  outline: 0;
  font-weight: 500;
  font-size: .8rem;
  color: #7a808a;
  min-width: 100px;
  padding: 0 28px;
  height: 38px;
  background: none;
  border: 1px solid #495057;
  vertical-align: middle;
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }
  .btn:hover {
    cursor: pointer; }

.danger {
  border-color: rgba(255, 68, 68, 0.25); }
  .danger:focus {
    border-color: rgba(255, 68, 68, 0.65);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 68, 68, 0.1); }

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 50px; }

.loading {
  position: relative;
  pointer-events: none;
  color: transparent !important; }
  .loading:after {
    position: absolute;
    animation: loading .5s infinite linear;
    border: .1rem solid;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: .8rem;
    left: 50%;
    margin-left: -.4rem;
    margin-top: -.4rem;
    top: 46%;
    width: .8rem;
    z-index: 1; }
  .loading:hover {
    color: transparent; }

@-webkit-keyframes loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font: 17px/1.2 "Inconsolata", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0 40px 40px 40px; }

.errors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  height: 75vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

.error-code {
  font-size: 1.7rem;
  margin: 0;
  font-weight: 300; }

.error-msg {
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
  text-align: center;
  white-space: pre-wrap; }

.opt {
  padding-bottom: 25px; }

.opt-title {
  font-weight: 500; }

.opt-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  margin: 15px 0; }

.component-wrapper .active {
  font-weight: 700; }

.no-btn {
  font-size: .9rem;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

input[type='checkbox'] {
  margin: 10px 0; }

main {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 140px);
  font-weight: 700; }

.list {
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  bottom: -25px;
  right: -20px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 2; }

.list span {
  padding: 2px; }

.list .last-editor {
  opacity: 1; }

.connections {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.connection {
  display: flex;
  justify-content: center;
  padding: 1px 0;
  opacity: 0.7;
  transition: opacity 0.5s linear; }
  .connection:hover {
    cursor: pointer; }

.flag {
  margin-top: 1px; }

.identifier {
  margin-left: 5px; }

.logs {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: min-content;
  margin-top: 5px;
  white-space: pre-wrap; }

iframe {
  display: none; }

span[role='presentation']::selection {
  background: #f1f3f5 !important; }

nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100px; }

.title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 1.2rem; }

.items {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  font-weight: 600;
  font-size: .85rem; }

.item {
  margin-left: 10px; }

div.right-pane::-webkit-scrollbar, div.bottom-pane::-webkit-scrollbar {
  width: 0px; }

.reflex-container > .reflex-element {
  display: flex;
  align-items: flex-start; }

.reflex-container.vertical > .reflex-splitter {
  margin: 0 15px;
  border: none;
  width: 2px; }

.reflex-container.horizontal > .reflex-splitter {
  margin: 15px 0;
  height: 2px;
  border: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active,
.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border: none; }

.pane-content {
  height: 100%;
  width: 100%; }

.CodeMirror {
  color: #495057; }

.codemirror-wrapper {
  height: 100%; }

.react-codemirror2 {
  height: 100%; }

.CodeMirror pre {
  padding: 0;
  padding-left: 4px; }

.CodeMirror-gutter-elt {
  top: -1px; }

.component-wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  align-self: center;
  width: 100%;
  font-size: .95rem;
  max-width: 1000px; }

.component-wrapper .titles {
  margin-bottom: 50px;
  line-height: 2;
  border-bottom: 2px solid; }

.notes {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

.note {
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid;
  padding: 15px 5px;
  transition: all 0.5s cubic-bezier(0.82, 0, 0.12, 1); }

.no-notes {
  text-align: center; }

.note-options {
  display: flex; }

.sort-options {
  display: flex;
  margin: 0 10px;
  font-size: 0.865rem; }

.sort-type {
  font-size: 0.85rem;
  margin: 2px 5px 5px 5px; }

.display-options {
  display: flex; }

.display-options img {
  margin-left: 7px; }

.note[data-display='large'] {
  height: 229px;
  margin-bottom: 40px; }

.note[data-display='small'] {
  height: 58px;
  margin-bottom: 20px; }

.notes-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.note div {
  pointer-events: none;
  user-select: none; }

.saved .CodeMirror-lines {
  padding: 0; }

.pages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.8rem;
  margin: 10px 0; }

.page {
  margin: 0 5px;
  padding: 1px; }

[data-active='true'] {
  font-weight: 700; }
