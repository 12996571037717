$default: (
  'theme': default,
  'background': #fff,
  'base-color': #000,
  'activeline-background': #e8f2ff,
  'matchingbracket': #fff,
  'linenumber': #999,
  'focused-linenumber': #999,
  'selected': #d7d4f0,
  'cursor': #000,
  'comment-style': normal,
  'comment': #236e25,
  'atom': #219,
  'number': #164,
  'property': #000,
  'keyword': #aa0d91,
  'string': #c41a16,
  'string-2': #c41a16,
  'variable': #000,
  'variable-2': #05a,
  'def': #00f,
  'operator': #000,
  'meta': #555,
  'reflex-splitter': #f1f1f1
);

$monochrome: (
  'theme': monochrome,
  'background': #fff,
  'base-color': #495057,
  'activeline-background': #fff,
  'matchingbracket': #fff,
  'linenumber': rgba(173,181,189,.75),
  'focused-linenumber': rgba(33,37,41,.75),
  'selected': #f1f3f5,
  'cursor': #00baff,
  'comment-style': normal,
  'comment': #adb5bd,
  'atom': #495057,
  'number': #495057,
  'property': #495057,
  'keyword': #495057,
  'string': #868e96,
  'string-2': #868e96,
  'variable': #495057,
  'variable-2': #495057,
  'def': #495057,
  'operator': #495057,
  'meta': #495057,
  'reflex-splitter': #f9f9f9
);

$monochrome-dark: (
  'theme': monochrome-dark,
  'background': #1c2023,
  'base-color': #bfbfbf,
  'activeline-background': #1c2023,
  'matchingbracket': #1c2023,
  'linenumber': #4e5661,
  'focused-linenumber': #bfbfbf,
  'selected': #2a3135,
  'cursor': #00baff,
  'comment-style': normal,
  'comment': #4a4f58,
  'atom': #bfbfbf,
  'number': #bfbfbf,
  'property': #bfbfbf,
  'keyword': #bfbfbf,
  'string': #575f6b,
  'string-2': #575f6b,
  'variable': #bfbfbf,
  'variable-2': #bfbfbf,
  'def': #bfbfbf,
  'operator': #bfbfbf,
  'meta': #bfbfbf,
  'reflex-splitter': #24282d
);

$ocean-dark: (
  'theme': ocean-dark,
  'background': #2b303b,
  'base-color': #c0c5ce,
  'activeline-background': #2b303b,
  'matchingbracket': #fff,
  'linenumber': #c0c5ce,
  'focused-linenumber': #c0c5ce,
  'selected': #505b66,
  'cursor': #c0c5ce,
  'comment-style': italic,
  'comment': #65737e,
  'atom': #d08770,
  'number': #d08770,
  'property': #bf616a,
  'keyword': #b48ead,
  'string': #a3be8c,
  'string-2': #a3be8c,
  'variable': #8fa1b3,
  'variable-2': #c0c5ce,
  'def': #c0c5ce,
  'operator': #c0c5ce,
  'meta': #c0c5ce,
  'reflex-splitter': #373b45
);

// can't distinguish between variables and functions for syntax highlighting
