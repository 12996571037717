@import 'partials/themes';
$code-font: 'Inconsolata', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$display-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$primary: #495057;

* {
  box-sizing: border-box; }

p {
  margin: 0; }

a {
  text-decoration: none;
  color: inherit;
  outline: 0; }

h1,h2,h3,h4,h5,h6 {
  margin: 0; }

ul,ol {
  padding: 0;
  margin: 0;
  list-style: none; }

img,i {
  vertical-align: bottom; }

::selection {
  background: #f1f3f5; }

button {
  outline: 0;
  background: none;
  border: none;
  font-weight: 500;
  padding: 0;
  &:hover {
    cursor: pointer; } }

.btn {
  outline: 0;
  font-weight: 500;
  font-size: .8rem;
  color: #7a808a;
  min-width: 100px;
  padding: 0 28px;
  height: 38px;
  background: none;
  border: 1px solid $primary; //#e6edf4
  vertical-align: middle;
  white-space: nowrap;
  // transition: all 150ms ease-out
  font-family: $display-font;
  &:hover {
    cursor: pointer; }
  &:focus {
    // border-color: #007eff
 } }    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)

.danger {
  border-color: rgba(255,68,68,.25);
  &:focus {
    border-color: rgba(255,68,68,.65);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 0 3px rgba(255,68,68,.1); } }

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 50px; }

.loading {
  position: relative;
  pointer-events: none;
  color: transparent !important;
  &:after {
    position: absolute;
    animation: loading .5s infinite linear;
    border: .1rem solid;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: .8rem;
    left: 50%;
    margin-left: -.4rem;
    margin-top: -.4rem;
    top: 46%;
    width: .8rem;
    z-index: 1; }
  &:hover {
    color: transparent; } }

// not working...
@-webkit-keyframes loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font: 17px/1.2 $code-font; }

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0 40px 40px 40px; }

.errors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  height: 75vh;
  font-family: $display-font; }

.error-code {
  font-size: 1.7rem;
  margin: 0;
  font-weight: 300; }

.error-msg {
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
  text-align: center;
  white-space: pre-wrap; }

.opt {
  padding-bottom: 25px; }

.opt-title {
  font-weight: 500; }

.opt-action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  margin: 15px 0; }

.component-wrapper .active {
  font-weight: 700; }

.no-btn {
  font-size: .9rem;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: $display-font; }

input[type='checkbox'] {
  margin: 10px 0; }

// main

main {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 140px);
  font-weight: 700; }

// list

.list {
  position: absolute;
  font-family: $display-font;
  bottom: -25px;
  right: -20px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 2; }

.list span {
  padding: 2px; }

.list .last-editor {
  opacity: 1; }

.connections {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.connection {
  display: flex;
  justify-content: center;
  padding: 1px 0;
  opacity: 0.7;
  transition: opacity 0.5s linear;
  &:hover {
    cursor: pointer; } }

.flag {
  margin-top: 1px; }

.identifier {
  margin-left: 5px; }

// logs, iframe

.logs {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: min-content;
  // height: 100vh
  margin-top: 5px;
  white-space: pre-wrap; }

iframe {
  display: none; }

span[role='presentation']::selection {
  background: #f1f3f5 !important; }

// nav

nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100px;
 }  // padding: 0 20px

.title {
  font-family: $display-font;
  font-weight: 600;
  font-size: 1.2rem; }

.items {
  font-family: $display-font;
  display: flex;
  font-weight: 600;
  font-size: .85rem; }

.item {
  margin-left: 10px; }

// reflex

div.right-pane::-webkit-scrollbar, div.bottom-pane::-webkit-scrollbar, {
  width: 0px; }

.reflex-container > .reflex-element {
  display: flex;
  align-items: flex-start; }

.reflex-container.vertical > .reflex-splitter {
  margin: 0 15px;
  border: none;
  width: 2px; }

.reflex-container.horizontal > .reflex-splitter {
  margin: 15px 0;
  height: 2px;
  border: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active,
.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border: none; }

.pane-content {
  height: 100%;
  width: 100%; }

// codemirror

.CodeMirror {
  color: $primary; }

.codemirror-wrapper {
  height: 100%; }

.react-codemirror2 {
  height: 100%; }

.CodeMirror pre {
  padding: 0;
  padding-left: 4px; }

.CodeMirror-gutter-elt {
  top: -1px; }

// notes

.component-wrapper {
  font-family: $display-font;
  align-self: center;
  width: 100%;
  font-size: .95rem;
  // margin-top: 50px
  max-width: 1000px;
 }  // border: 1px solid blue

.component-wrapper .titles {
  margin-bottom: 50px;
  line-height: 2;
  border-bottom: 2px solid; }

.notes {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
 }  // border: 1px solid red

.note {
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid;
  padding: 15px 5px;
  transition: all 0.5s cubic-bezier(.82, 0, .12, 1);
 }  // border-radius: 4px

.no-notes {
  text-align: center; }

.note-options {
  display: flex; }

.sort-options {
  display: flex;
  margin: 0 10px;
  font-size: 0.865rem; }

.sort-type {
  font-size: 0.85rem;
  // font-weight: 500
  margin: 2px 5px 5px 5px; }

.display-options {
  display: flex; }

.display-options img {
  margin-left: 7px; }

.note[data-display='large'] {
  height: 229px;
  margin-bottom: 40px; }

.note[data-display='small'] {
  height: 58px;
  margin-bottom: 20px; }

.notes-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.note div {
  pointer-events: none;
  user-select: none; }

.saved .CodeMirror-lines {
  padding: 0; }

// pages

.pages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.8rem;
  margin: 10px 0; }

.page {
  margin: 0 5px;
  padding: 1px; }

[data-active='true'] {
  font-weight: 700; }
